import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Table, Menu, Dropdown, message } from 'antd';
import axios from "axios";

import { getFn } from '../../functions';
import PageHeader from '../../components/PageHeader/PageHeader';
import { OrgLink } from '../../components/Links';
import Button from '../../components/Buttons/Button';
import { MoreOutlined } from '@ant-design/icons';

import SelectOwnerModal from "./SelectOwnerModal";
import ActivityDetailsModal from "./ActivityDetailsModal";

import "./Bulk.scss";

const testAcc = 'nordicengineeringaps-e75ea1';

const Bulk = ({
  activeAccount,
  isAdmin,
  accountUsers
}) => {
  const isAccountAdmin = !!(activeAccount && activeAccount.users);
  const [orgs, setOrgs] = useState({ all: [], filtered: [] });
  const [users, setUsers] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectOwnerModalIsVisible, setSelectOwnerModalIsVisible] = useState(false);
  const [activityDetailsModalIsVisible, setActivityDetailsModalIsVisible] = useState(false);
  const [expandedText, setExpandedText] = useState(-1);
  const [singleOrgActionId, setSingleOrgActionId] = useState(null);

  useEffect(() => {
    if (!orgs.filtered) return;

    const priorities = orgs.filtered.reduce((res, item) => {
      const p = item['Prioritering'];
      if (!p) return res;
      const i = res.findIndex(group => group.priority === p);
      if (i === -1) {
        res.push({
          priority: p,
          orgs: [{...item}]
        });
      } else {
        res[i].orgs.push({...item});
      }
      return res;
    }, []);
    priorities.sort((a, b) => {
      return b.priority - a.priority
    })
    setPriorities(priorities);
  }, [orgs.filtered]);

  useEffect(() => {
    if (!orgs) return;
    if (accountUsers.activeUser) {
      const activeUser = accountUsers.list.find(u => u.id === accountUsers.activeUser);
      if (activeUser) setOrgs({ ...orgs, filtered: orgs.all.filter(o => o.Owner === activeUser.name)});
    } else {
      setOrgs({ ...orgs, filtered: orgs.all });
    }
  }, [accountUsers.activeUser]);

  useEffect(() => {
    const loadOrgs = async () => {
      try {
        setIsLoading(true);
        const fn = getFn('openrpaGetOrganizations');
        const res = await fn({ account: testAcc });
        setOrgs({ all: res.data, filtered: res.data });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (isAdmin || isAccountAdmin) loadOrgs();
  }, []);

  const setOwner = useCallback(async (uid) => {
    let orgsIds = [...selectedRowKeys];
    
    if (singleOrgActionId !== null) {
      orgsIds = [singleOrgActionId];
      setSingleOrgActionId(null);
    }
    
    try {
      //setIsLoading(true);
      const fn = getFn('openrpaSetOrgOwner');
      const res = await fn({ account: testAcc, orgsIds, ownerId: uid });
      console.log(res)
      message.success('Owner successfully changed');
      /*console.log(await axios.put(`https://sloif.demo.openiap.io/bulk/setorgowner?sloifid=nordicengineeringaps-e75ea1`, {
        orgids: orgsIds.map(id => 1*id),
        owner_id: 1*uid
      }, {
        auth: {
          username: "sloifnordicengineeringaps",
          password: "rgr3H4revJg3grKegr3Q4344"
        },
      }))*/
     
    } catch (err) {
      console.log(err);
    } finally {
      //setIsLoading(false);
    }
  }, [selectedRowKeys]);

  const createActivities = useCallback(async (data) => {
    let orgsIds = [...selectedRowKeys];
    if (singleOrgActionId !== null) {
      orgsIds = [singleOrgActionId];
      setSingleOrgActionId(null);
    }
    try {
      //setIsLoading(true);
      const fn = getFn('openrpaCreateActivities');
      const res = await fn({ account: testAcc, orgsIds, activityData: {...data} });
      console.log(res);
      message.success('Activities successfully created');
      /*console.log(await axios.put(`https://sloif.demo.openiap.io/bulk/createorgactivity?sloifid=nordicengineeringaps-e75ea1`, {
        orgids: orgsIds.map(id => 1*id),
        subject: data.subject
      }, {
        auth: {
          username: "sloifnordicengineeringaps",
          password: "rgr3H4revJg3grKegr3Q4344"
        },
      }))*/

    } catch (err) {
      console.log(err);
    } finally {
      //setIsLoading(false);
    }
  });

  const prioritiesColumns = [
    {
      title: 'Priority',
      dataIndex: 'priority',
      defaultSortOrder: 'ascend'
    },
    {
      title: 'Organizations',
      key: 'organizations',
      render: (group) => {
        return <span className="group-number"><strong>{group.orgs.length}</strong> {group.orgs.length === 1 ? 'organization' : 'organizations'}</span>
      }
    },
  ];

  const orgsColumns = [
    {
      title: 'Organization',
      dataIndex: 'organizationsname',
      render: (text, org) => (
        <OrgLink id={org.id} account={activeAccount}><strong>{org.organizationsname}</strong></OrgLink>
      ),
      sorter: (a, b) => b.organizationsname.localeCompare(a.organizationsname),
    },
    {
      title: 'Owner',
      dataIndex: 'Owner',
      sorter: (a, b) => a.Prioritering - b.Prioritering,
    },
    {
      title: 'Notes',
      width: '55%',
      render: (item) => <div className={`bulk-org-desc ${expandedText === item._id ? 'bulk-org-desc_visible' : ''}`}
        onClick={() => {
          if (expandedText !== item._id) {
            setExpandedText(item._id)
          } else {
            setExpandedText(null)
          }
          
        }}
        dangerouslySetInnerHTML={{ __html: item['Notes concatenated'] }}
        title={expandedText !== item._id ? "Click to expand" : "Click to collapse"} />
    },
    {
      title: '',
      render: (item) => {
        const DropdownMenu = <Menu>
          <Menu.Item key="0"><a href="#" onClick={() => {
            console.log(item)
            setSingleOrgActionId(item['orgid']);
            setSelectOwnerModalIsVisible(true);
          }}>Change owner</a></Menu.Item>
          <Menu.Item key="1"><a href="#" onClick={() => {
            setSingleOrgActionId(item['orgid']);
            setSelectOwnerModalIsVisible(true);
          }}>Create activity</a></Menu.Item>
        </Menu>;

        return <Dropdown overlay={DropdownMenu} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ fontSize: '20px' }}>
            <MoreOutlined />
          </a>
        </Dropdown>
      }
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (v) => setSelectedRowKeys(v),
  };

  if (!isAccountAdmin && !isAdmin) return <Redirect to='/' />;
  
  if (orgs.all) {
    orgs.all.map(o => {
      if (o['organizationsname'].toLowerCase().indexOf('elektro-motor aps') !== -1) {
        console.log(o['Owner'])
      }
    })
  }

  return <>
    <PageHeader 
      title="Planning" 
      description={"Shows the organizations you should consider doing presales on."}
      showUserSelect={true}
      information={{
        title: 'Instructions',
        content: <>
          <p>Planning shows organizations without open activities that are not opted out, where the amount of days from last done activity is higher than normal average time. </p>
          <p>The prioritization is a point system where the most valuable ones, based on offers sent, former purchases and if the amount of days from the last won deal is higher than the normal interval of won deal time.The higher the points the more valuable.</p>
          <p>You use it by unfolding a group and reading the concatenated notes on the right, and then take a decision on either changing the owner of the organization by pressing change owner or adding an open activity to them. </p>
          <p>When adding an open activity it will be added for today, but you can change the date.</p>
          <p>You will also have the choice to change the activity owner, the default will be the organisation owner, but you can change it.</p>
          <p>The purpose of changing the organization owner is to be able to distribute the planning, so that a specific owner can see the organizations the owner is the owner of, and thereby have the responsibility of adding open activities to the relevant ones.</p>
        </>
      }} />
    
    <div className="content">
      <div className={`content__wrap ${selectedRowKeys.length > 0 ? 'content__wrap_wheader' : ''}`}>
        {selectedRowKeys.length > 0 && <div className="action-btns">
          <Button type="primary" className="invite-btn" onClick={() => setSelectOwnerModalIsVisible(true)}>Change owner</Button>
          <Button type="primary" className="invite-btn" onClick={() => setActivityDetailsModalIsVisible(true)}>Create activities</Button>
        </div>}

        <Table
          pagination={false}
          columns={prioritiesColumns}
          rowKey={p => p.priority}
          loading={isLoading}
          expandable={{
            rowExpandable: p => p.orgs.length,
            expandedRowRender: p => <div className="group-org-list">
              <Table
                rowSelection={rowSelection}
                pagination={{ defaultPageSize: 50, pageSizeOptions: [50, 100, 250, 500, 1000] }}
                className="table bulk-orgs-table"
                rowKey={i => i.orgid}
                columns={orgsColumns}
                dataSource={p.orgs} />
            </div>
          }}
          dataSource={priorities} />
        {orgs && orgs.filtered && <span className="table-total table-total_inline">Total organizations: <strong>{orgs.filtered.length}</strong></span>}
      </div>
    </div>

    {selectOwnerModalIsVisible && <SelectOwnerModal 
      onCancel={() => setSelectOwnerModalIsVisible(false)} 
      onSelect={(uid) => {
        setSelectOwnerModalIsVisible(false);
        setOwner(uid);
      }}
    />}

    {activityDetailsModalIsVisible && <ActivityDetailsModal
      onCancel={() => setActivityDetailsModalIsVisible(false)}
      onSubmit={(data) => {
        setActivityDetailsModalIsVisible(false);
        createActivities(data)
      }}
    />}
  </>;
}

const mapState = (state) => ({
  activeAccount: state.activeAccount,
  isAdmin: state.isAdmin,
  accountUsers: state.accountUsers
});

export default connect(mapState, {})(Bulk);