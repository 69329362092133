import React from 'react';
import { Tabs } from 'antd';
import './Tabs.scss';

const { TabPane } = Tabs;

const TabsComponent = ({ subtabs }) => {
  return (
    <div className="tabs">
      <Tabs defaultActiveKey="1" onChange={() => { }}>
        {subtabs.map((tab, i) => (
          <TabPane tab={tab.title} key={`tab-${i}`}>
            {tab.component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default TabsComponent;