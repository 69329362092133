import React from 'react';
import { Modal, Button } from 'antd';

//const { Title, Paragraph } = Typography;

const InformationModal = ({
  title,
  content,
  onClose
}) => {
  return <Modal
    title={title}
    onCancel={onClose}
    visible={true}
    footer={[
      <Button key="submit" type="primary" onClick={onClose}>Close</Button>
    ]}>
    {content}
  </Modal>
};

export default InformationModal;