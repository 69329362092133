import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Form, message } from 'antd';

import UserAutoComplete from '../UserAutoComplete/UserAutoComplete';
import { getFn } from '../../functions';
import { fetchUsers as fetchSystemUsers } from '../../store/slices/systemUsers';

import "./InviteUserModal.scss";

const InviteUserModal = ({
  onCancel,
  onInvite,
  isAdmin,
  systemUsers,
  inviteToAccount,
  activeAccount,
  fetchSystemUsers,
  currentUser,
  accountAdmins
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userDetailsVisible, setUserDetailsVisible] = useState(false);
  const [userSuggestions, setUserSuggestions] = useState([]);

  const [form] = Form.useForm();

  const onSubmit = useCallback(async (values) => {
    setIsLoading(true);
    try {
      await onInvite(values);
      closeModal();
      setIsLoading(false);
      if (!values.firstName && !values.lastName) {
        message.success('User succesfully added');
      } else {
        message.success('User succesfully invited');
      }
    } catch(err) {
      closeModal();
      setIsLoading(false);
      message.error('Error inviting user');
    }
  }, []);

  const closeModal = useCallback(async () => {
    form.resetFields();
    onCancel();
  }, []);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const fn = getFn('getAccessableAccountsUsers');
        const res = await fn({ account: activeAccount.apiPath });
        if (res.data && res.data.length) setUserSuggestions(res.data);
      } catch (err) { 
        console.log(err); 
      }
    }

    if (inviteToAccount) {
      if (isAdmin) {
        if (!systemUsers.length) fetchSystemUsers();
        setUserSuggestions(systemUsers.filter(u => {
          return (u.uid !== currentUser.uid && accountAdmins.findIndex(au => au.uid === u.uid) === -1);
        }));
      } else {
        fetchSuggestions();
      }
    } 
  }, [inviteToAccount, isAdmin, systemUsers]);

  return (
    <Modal
      className="invite-user-modal"
      title={"Invite new user"}
      visible={true}
      confirmLoading={isLoading}
      cancelText="Cancel"
      okText={"Invite"}
      onOk={form.submit}
      onCancel={closeModal}>

      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        initialValues={{}}
        onFinish={onSubmit}>

        {inviteToAccount ? (
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input email',
              },
            ]}>
            <UserAutoComplete suggestions={userSuggestions} 
              onSelect={(res) => {
                if (res.isExistingUser) {
                  setUserDetailsVisible(false);
                } else {
                  setUserDetailsVisible(true);
                }
              }} />
          </Form.Item>) :

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input email',
              },
            ]}>
            <Input />
          </Form.Item>
        }

        {inviteToAccount && false ? 
          <span className="invite__new-user" onClick={() => setUserDetailsVisible(true)}>Add details for new user</span> 
        : null}

        {(inviteToAccount && !userDetailsVisible) ? null :<>
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Please enter first name',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please input last name',
              },
            ]}>
            <Input />
          </Form.Item>
        </>}
      </Form>
    </Modal>
  );
}

const mapState = (state) => ({
  systemUsers: state.systemUsers,
  isAdmin: state.isAdmin,
  activeAccount: state.activeAccount,
  currentUser: state.currentUser,
  accountAdmins: state.accountAdmins,
});

const mapDispatch = (dispatch) => ({
  fetchSystemUsers: () => dispatch(fetchSystemUsers()),
});

export default connect(mapState, mapDispatch)(InviteUserModal);