import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchOrganizations } from '../store/slices/organizations';
import { fetchKpis } from '../store/slices/kpis';
import { fetchFilters } from '../store/slices/filters';
import { setUiFlag } from '../store/slices/ui';

const useOrganizationsData = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (isSilent = false) => {
    setIsLoading(true);
    if (!isSilent) dispatch(setUiFlag({key: 'dataIsFetching', value: true}));

    Promise.all([
      await dispatch(fetchOrganizations()),
      await dispatch(fetchKpis()),
      await dispatch(fetchFilters())
    ]);
    
    setIsLoading(false);
    if (!isSilent) dispatch(setUiFlag({key: 'dataIsFetching', value: false}));
  };

  return [isLoading, fetchData];
}

export default useOrganizationsData;