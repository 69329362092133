import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Select } from 'antd';

import { setActiveAccount } from '../../store/slices/activeAccount';
import UserDropdown from './UserDropdown';
import NavigationMenu from './NavigationMenu';

import styles from './Drawer.module.scss';

const { Option } = Select;

const AppDrawer = ({ 
  accounts,
  activeAccount, 
  setActiveAccount,
}) => {
  
  return (
    <Drawer
      title={null}
      placement={'left'}
      closable={false}
      width={280}
      onClose={null}
      visible={true}
      mask={false}
      className={styles['app-drawer']}>
        <span className={styles.logo} />

        <Select className={styles['account-selector']}
          placeholder="Select account" 
          value={activeAccount ? activeAccount.apiPath : null} 
          onChange={async (value) => {
            const account = accounts.find(a => a.apiPath === value);
            setActiveAccount(account);
          }}>
          {accounts.map((a, i) => {
            return <Option key={i} value={a.apiPath}>{a.name}</Option>;
          })}
        </Select>

        <NavigationMenu />
        <UserDropdown />

    </Drawer>
  );
}

const mapState = (state) => ({
  accounts: state.accounts,
  activeAccount: state.activeAccount,
  isAdmin: state.isAdmin,
  currentUser: state.currentUser
});

const mapDispatch = (dispatch) => ({
  setActiveAccount: (acc) => dispatch(setActiveAccount(acc)),
  logout: () => dispatch({ type: 'USER_LOGOUT' })
});

export default connect(mapState, mapDispatch)(AppDrawer);