import { createSlice } from '@reduxjs/toolkit';
import { getFn } from '../../functions';
import { setActiveAccount } from './activeAccount'

import { 
  getAccountAdmins, 
  changeAccountUserRole, 
  inviteUser as inviteUserFn,
  deleteUserFromAccount
} from '../../functions';

const accountAdmins = createSlice({
  name: 'accountAdmins',
  initialState: [],
  reducers: {
    initAccountAdmins: (state, action) => {
      if (action.payload) return action.payload;
      return [];
    },
    changeRole: (state, action) => {
      let index = state.findIndex(u => u.uid === action.payload.uid);
      state[index].role = action.payload.role;
      return state;
    },
    addNewUser: (state, action) => {
      state.push(action.payload);
      return state;
    },
    removeUser: (state, action) => {
      const index = state.findIndex(u => u.uid === action.payload);
      state.splice(index, 1);
      return state;
    }
  },
  extraReducers: {
    [setActiveAccount]: (state, action) => {
      return [];
    }
  }
});

const fetchAccountAdmins = () => async (dispatch, getState) => {
  let state = getState();
  try {
    const res = await getAccountAdmins(state.activeAccount.apiPath);
    const resArray = Object.keys(res).map(u => res[u]);
    dispatch(accountAdmins.actions.initAccountAdmins(resArray));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const changeUserRole = (uid, role) => async (dispatch, getState) => {
  const state = getState();
  try {
    const res = await changeAccountUserRole(state.activeAccount.apiPath, uid, role);
    dispatch(accountAdmins.actions.changeRole({ uid, role }));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const inviteUser = (user) => async (dispatch, getState) => {
  const state = getState();
  try {
    const uid = await inviteUserFn(user, state.activeAccount.apiPath);
    dispatch(accountAdmins.actions.addNewUser({ ...user, uid }));
    return uid;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

const deleteUser = (uid) => async (dispatch, getState) => {
  const state = getState();
  try {
    await deleteUserFromAccount(uid, state.activeAccount.apiPath);
    dispatch(accountAdmins.actions.removeUser(uid));
    return uid;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

const addUserToaccount = (user) => async (dispatch, getState) => {
  const state = getState();
  try {
    const res = await getFn('addUserToAccount')({ user, account: state.activeAccount.apiPath });
    dispatch(accountAdmins.actions.addNewUser(res.data));
    return true;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

const { initAccountAdmins } = accountAdmins.actions;
export { initAccountAdmins, fetchAccountAdmins, changeUserRole, inviteUser, deleteUser, addUserToaccount };

export default accountAdmins.reducer;