import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Table } from 'antd';

import { getOrganizationGroups } from '../../api';
import { OrgLink, FilterLink } from '../../components/Links';
import { actions, descriptions } from './Groups.Descriptions';


const GroupsTab = ({
  organizations,
  filters,
  activeAccount,
  dataIsFetching
}) => {
  const [groups, setGroups] = useState([]);
  const [total, setTotal] = useState(0);

  const columns = [
    { 
      title: 'Priority', 
      key: 'priority',
      render: (group) => {
        const filter = filters.length ? filters.find(f => f.name === `b2b-priority-${group.priority}`) : null;
        return (filter ? <FilterLink account={activeAccount} id={filter.id} priority={group.priority} /> : <span className="group-score">{group.priority}</span>)
      }
    },
    { 
      title: 'Organizations', 
      key: 'organizations',
      render: (group) => {
        return <span className="group-number"><strong>{group.orgs.length}</strong> {group.orgs.length === 1 ? 'organization' : 'organizations'}</span>
      }
    },
    { 
      title: 'Action', 
      key: 'action',
      width: '25%',
      render: (group) => {
        return (actions[1 * group.priority - 1] ? <p className="group-action">{actions[1 * group.priority - 1]}</p> : null);
      }
    },
    {
      title: 'Description',
      key: 'description',
      width: '50%',
      render: (group) => {
        return (descriptions[1 * group.priority - 1] ? <p className="group-desc">{descriptions[1 * group.priority - 1]}</p> : null);
      }
    },
  ];

  const orgsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, org) => (
        <OrgLink id={org.id} account={activeAccount}><strong>{org.name}</strong></OrgLink>
      ),
      sorter: (a, b) => b.name.localeCompare(a.name),
    },
    {
      title: '# of open activities',
      dataIndex: 'openActivities',
      sorter: (a, b) => a.openActivities - b.openActivities,
    },
    {
      title: '# of open deals',
      dataIndex: 'openDeals',
      sorter: (a, b) => a.openDeals - b.openDeals,
    },
    {
      title: '# of contacts',
      dataIndex: 'numOfContacts',
      sorter: (a, b) => a.numOfContacts - b.numOfContacts,
    },
  ];

  useEffect(() => {
    if (organizations && organizations.length) {
      setGroups(getOrganizationGroups(organizations));
    } else {
      setGroups([]);
    }
  }, [organizations]);

  useEffect(() => {
    let t = 0;
    if (groups.length > 0) {
      groups.forEach(g => (t += g.orgs.length));
    }
    setTotal(t);
  }, [groups]);

  return <>
    <Table
      loading={dataIsFetching}
      pagination={false}
      columns={columns}
      expandable={{
        rowExpandable: group => group.orgs.length,
        expandedRowRender: group => <div className="group-org-list">
          <Table
            pagination={{ defaultPageSize: 50, pageSizeOptions: [50, 100, 250, 500, 1000] }}
            className="table"
            rowKey={i => i.id}
            columns={orgsColumns}
            dataSource={group.orgs} />
        </div>
      }}
      dataSource={groups}
    />,

    {total > 0 && <span className="table-total table-total_inline">Total organizations: <strong>{total}</strong></span>}
  </>;
}

const mapState = (state) => ({
  organizations: state.organizations.filtered,
  filters: state.filters,
  activeAccount: state.activeAccount,
  dataIsFetching: state.ui.dataIsFetching
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(GroupsTab);