import React from 'react';
import { Button } from 'antd';

import styles from "./Button.module.scss";

const Btn = ({
  type = 'primary',
  children,
  onClick,
  className = ''
}) => {
  return <Button type={type} onClick={onClick} className={[styles.btn, styles[`btn-${type}`], className].join(' ')}>{children}</Button>
};

export default Btn;