import { createContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import "firebase/auth";
import "firebase/database";


const firebaseApp = firebase.initializeApp({
  projectId: 'b2b2-b9190',
  apiKey: 'AIzaSyASxIm7Lb_RIfnK-93fmIm3onXpfad85FQ',
  databaseURL: 'https://b2b2-b9190.firebaseio.com/'
});

firebase.functions();
firebase.database();

export { firebaseApp };
export default createContext()