import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { runCalculations as runCalculationsFn } from '../functions';
import { setUiFlag } from '../store/slices/ui';
import useOrganizationsData from './useOrganizationsData';

const useRunCalculations = () => {
  //const dispatch = useDispatch(); 
  const [, fetchOrgsData] = useOrganizationsData();

  const runCalculations = async (account) => {
    let hideMessage;
    try {
      //dispatch(setUiFlag({ key: 'runCalculationsInProgress', value: true }));
      hideMessage = message.loading('Calculations in progress', 0);
      await runCalculationsFn(account);
    } catch(err) {
      console.log(err);
    } finally {
      hideMessage();
      await fetchOrgsData();
      //dispatch(setUiFlag({ key: 'runCalculationsInProgress', value: false }));
    }
  };

  return [runCalculations];
}

export default useRunCalculations;