import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Form, message, Tooltip, Radio, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { addNewAccount, updateAccount } from '../../store/slices/accounts';
import UserAutoComplete from '../../components/UserAutoComplete/UserAutoComplete';
import useRunCalculations from '../../hooks/useRunCalculations';

import * as consts from '../../consts';

import './AccountModal.scss';

const { Option } = Select;

const preparePath = (v) => {
  if (!v) return '';
  return v.trim().toLowerCase();
}

const AccountModal = ({
  onCancel,
  editingItem = null,
  addNewAccount,
  updateAccount,
  accounts,
  systemUsers
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [form] = Form.useForm();
  const [runCalculations] = useRunCalculations();
  const [accountType, setAccountType] = useState(consts.ACC_TYPE_PIPEDRIVE);

  useEffect(() => {
    if (editingItem && editingItem.apiPath) {
      setEditingItemId(editingItem.apiPath);
    }
    if (editingItem && editingItem.type !== consts.ACC_TYPE_PIPEDRIVE) {
      setAccountType(editingItem.type);
    }
  }, [editingItem]);

  const onSubmit = useCallback(async (v) => {
    let values = { ...v, type: accountType };
    values.apiPath = preparePath(values.apiPath);
    if (accountType === consts.ACC_TYPE_PIPEDRIVE && values.apiPath.indexOf('.pipedrive.com')) {
      values.apiPath = values.apiPath.replace('.pipedrive.com', '');
    }

    if (accountType === consts.ACC_TYPE_WEBCRM) {
      values.apiPath = values.name.replace(/ /g, '').replace(/\./g, '').replace(/\//g, '').toLowerCase().trim();
    }
    
    console.log('VALUES', values);

    try {
      let mes = 'Account succesfully created';
      setIsLoading(true);
      if (editingItem) {
        await updateAccount(editingItemId, values);
        mes = 'Account succesfully updated';
      } else {
        await addNewAccount(values);
      }
      setIsLoading(false);
      closeModal();
      message.success(mes);
      if (!editingItem) await runCalculations(values.apiPath);
    } catch(err) {
      console.log(err);
    }
  }, [editingItemId, accountType]);
  
  const closeModal = useCallback(async () => {
    form.resetFields();
    onCancel();
  });

  //console.log(editingItem);

  return (
    <Modal
      className="account-modal"
      title={editingItem ? "Edit Account" : "Add Account"}
      visible={true}
      confirmLoading={isLoading}
      cancelText="Cancel"
      okText={editingItem ? "Save" : "Create Account"}
      onOk={form.submit}
      onCancel={closeModal}>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        initialValues={editingItem}
        onFinish={onSubmit}>
        
        {!editingItem && <Form.Item label="Account type">
          <Radio.Group onChange={(e) => { 
            setAccountType(e.target.value);
          }} defaultValue={accountType} value={accountType}>
            <Radio value={consts.ACC_TYPE_PIPEDRIVE}>Pipedrive</Radio>
            <Radio value={consts.ACC_TYPE_WEBCRM}>webCRM</Radio>
          </Radio.Group>
        </Form.Item>}
        
        <Form.Item
          label="Account name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input account name',
            },
          ]}>
          <Input />
        </Form.Item>

        {!editingItem && (accountType !== consts.ACC_TYPE_WEBCRM) && <Form.Item
          label="Pipedrive URL"
          name="apiPath"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Please enter Pipedrive URL',
            },
            {
              validator: (_, value) => {
                value = preparePath(value);
                if (value.indexOf('.pipedrive.com')) {
                  value = value.replace('.pipedrive.com', '');
                }

                if (!/^[A-Za-z0-9_\-]*$/.test(value)) {
                  return Promise.reject('Account name is incorrect, it can only include letters, digits, underscores and dashes');
                }

                const index = accounts.findIndex(a => a.apiPath === value);
                return (index === -1 ? Promise.resolve() 
                  : Promise.reject('Account with this path already exists'));
              }
            },
          ]}>
          <Input suffix={
            <Tooltip title="Example: account-name.pipedrive.com or simply account-name">
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          } />
        </Form.Item>}

        <Form.Item
          label="Api token"
          name="apiToken"
          rules={[
            {
              required: true,
              message: 'Please input api token',
            },
          ]}>
          <Input />
        </Form.Item>
        
        {accountType === consts.ACC_TYPE_WEBCRM || (editingItem && editingItem.type === consts.ACC_TYPE_WEBCRM) ? (
          <Form.Item label="Custom field id">
            <Form.Item name="customFieldId"
              rules={[ { required: true, message: 'Please fill custom field id', } ]}>
              <Select>
                {[...Array(15)].map((v, i) => <Option key={i} value={i + 1}>{i + 1}</Option>)}
              </Select>
            </Form.Item>
            
            <small>
              To configure a custom field, go to "Configuration -> Customfields" in your webCRM account and add a new field with type "Positive Integer"
            </small>
          </Form.Item>
        ) : null}

        {!editingItem && <Form.Item
          label="Administrator"
          name="admin"
          rules={[
            {
              validator: (_, value) => {
                if (!value || !value.length) return Promise.resolve();
                const l = systemUsers.findIndex(a => a.email === value);
                return (l !== -1 ? Promise.resolve() : Promise.reject('Please select an existing user'));
              }
            },
          ]}>
          <UserAutoComplete suggestions={systemUsers} onSelect={(value, record) => null} />
        </Form.Item>}
      </Form>

    </Modal>
  );
}

const mapState = (state) => ({
  accounts: state.accounts,
  systemUsers: state.systemUsers
});

const mapDispatch = (dispatch) => ({
  addNewAccount: (values) => dispatch(addNewAccount(values)),
  updateAccount: (accountId, values) => dispatch(updateAccount(accountId, values))
});

export default connect(mapState, mapDispatch)(AccountModal);