import { createSlice } from '@reduxjs/toolkit';

import { getOrganizations } from '../../functions';
import { setActiveAccount } from './activeAccount';

const filterOrgsByLabel = (orgs, labels) => {
  if (!labels || !labels.length) {
    return orgs;
  }

  return orgs.filter(o => {
    if ((o.label && labels.indexOf(o.label) !== -1) || labels.indexOf(-1) !== -1 && !o.label) {
      return true;
    }

    return false;
  });
};

const filterOrgsByUser = (orgs, id) => {
  return orgs.filter(o => o.ownerId === id);
};

const organizations = createSlice({
  name: 'organizations',
  initialState: {
    all: [],
    filtered: [],
    filteredByUser: [],
  },
  reducers: {
    initOrganizations: (state, action) => {
      return { ...state, all: action.payload, timestamp: new Date().getTime() };
    },
    initFiltered: (state, action) => {
      return { ...state, filtered: action.payload };
    }
  },
  extraReducers: {
    [setActiveAccount]: (state, action) => {
      return { all: [], filtered: [] };
    },
  }
});

const fetchOrganizations = () => async (dispatch, getState) => {
  const state = getState();

  try {
    let res = await getOrganizations(state.activeAccount.apiPath);
    dispatch(organizations.actions.initOrganizations(res));
    if (state.activeAccount.labels && state.activeAccount.labels.length > 0) {
      let filtered = filterOrgsByLabel(res, state.activeAccount.labels);
      dispatch(organizations.actions.initFiltered(filtered));
    } else {
      dispatch(organizations.actions.initFiltered(res));
    }

    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
}

const filterOrganizationsByUser = (user) => (dispatch, getState) => {
  const state = getState();
  const filteredByLabels = filterOrgsByLabel(state.organizations.all, state.activeAccount.labels);
  let res = [];

  if (user === 'all') {
    res = filteredByLabels;
  } else {
    res = filterOrgsByUser(filteredByLabels, user);
  }

  dispatch(organizations.actions.initFiltered(res));
}

export { fetchOrganizations, filterOrganizationsByUser };
export default organizations.reducer;