import { createSlice } from '@reduxjs/toolkit';

//import { getAllAccounts } from '../../api';
import { 
  getUserAccounts, 
  createAccount, 
  deleteAccount as deleteAccountFn,
  updateAccount as updateAccountFn 
} from '../../functions';

const accounts = createSlice({
  name: 'accounts',
  initialState: [],
  reducers: {
    initAccounts: (state, action) => {
      return action.payload;
    },
    addAccount: (state, action) => {
      state.push(action.payload);
      return state;
    },
    removeAccount: (state, action) => {
      const index = state.findIndex(acc => acc.apiPath === action.payload);
      state.splice(index, 1);
      return state;
    },
    updateAccountDetails: (state, action) => {
      const index = state.findIndex(acc => acc.apiPath === action.payload.accountId);
      state[index] = { ...state[index], ...action.payload.values };
      return state;
    }
  },
});

const fetchAccounts = () => async (dispatch, getState) => {  
  try {
    const res = await getUserAccounts();
    dispatch(accounts.actions.initAccounts(res));
    return res;
  } catch(err) {
    console.log(err);
    return err;
  }  
}

const addNewAccount = (values) => async (dispatch, getState) => {
  try {
    const res = await createAccount(values);
    dispatch(accounts.actions.addAccount(values));
    return res;
  } catch(err) {
    console.log(err);
    return err;
  }
};

const deleteAccount = (accountId) => async (dispatch, getState) => {
  try {
    const res = await deleteAccountFn(accountId);
    dispatch(accounts.actions.removeAccount(accountId));
  } catch(err) {
    console.log(err);
    throw new Error(err);
  }
};

const updateAccount = (accountId, values) => async (dispatch, getState) => {
  try {
    const res = await updateAccountFn(accountId, values);
    console.log(accountId, values);
    dispatch(accounts.actions.updateAccountDetails({accountId, values}));
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

const { initAccounts } = accounts.actions;

export { initAccounts, fetchAccounts, addNewAccount, deleteAccount, updateAccount };

export default accounts.reducer;