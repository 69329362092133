import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Checkbox, Popconfirm, message, Empty } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import Button from '../../components/Buttons/Button';
import { fetchUsers, changeUserRole, inviteUser, deleteUser } from '../../store/slices/systemUsers';
import InviteUserModal from '../../components/InviteUserModal/InviteUserModal';

const Users = ({
  users,
  fetchUsers,
  changeUserRole,
  inviteUser,
  deleteUser,
  currentUser
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inviteModalIsVisible, setInviteModalIsVisible] = useState(false);

  const columns = [
    { title: 'Email', dataIndex: 'email' },
    { title: 'Name', dataIndex: 'displayName' },
    {
      title: 'System administrator',
      dataIndex: 'systemAdmin',
      render: (text, user) => (currentUser.uid !== user.uid ? 
        <Checkbox onChange={async (e) => {
          const val = e.target.checked;
          let newRole = (val ? 'admin' : 'user');
          setIsLoading(true);
          await changeUserRole(user.uid, newRole)
          setIsLoading(false);
        }}
          checked={user.customClaims && user.customClaims.role && user.customClaims.role === 'admin'}
        /> : null)
    },
    {
      title: '',
      render: (txt, user) => ((!user.customClaims || !user.customClaims.role || user.customClaims.role !== 'admin') ? <Popconfirm
        title="Are you sure you want to delete user?"
        onConfirm={async () => {
          setIsLoading(true);
          try {
            await deleteUser(user.uid);
            message.success('User removed successfully');
          } catch(err) {
            message.error('Error removing user');
          }
          setIsLoading(false);
        }}
        okText="Yes"
        cancelText="No">
        <a className="table__action-link table__action-link_warning"><DeleteOutlined />&nbsp;Delete</a>
      </Popconfirm> : null)
    }
  ]

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      await fetchUsers();
      setIsLoading(false);
    }
    init();
  }, []);

  return (<>
    <div className="action-btns">
      <Button type="primary" className="invite-btn" onClick={() => setInviteModalIsVisible(true)}>Invite new user</Button>
    </div>

    <Table 
      locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No users" /> }}
      pagination={false}
      className="table"
      loading={isLoading}
      rowKey={i => i.uid}
      columns={columns}
      dataSource={users} />
    
    {inviteModalIsVisible &&
      <InviteUserModal onCancel={() => setInviteModalIsVisible(false)} onInvite={async (values) => {
        setIsLoading(true);
        await inviteUser(values);
        setIsLoading(false);
      }} />
    }
  </>
  );
};

const mapState = (state) => ({
  users: state.systemUsers,
  currentUser: state.currentUser
});

const mapDispatch = (dispatch) => ({
  fetchUsers: () => dispatch(fetchUsers()),
  changeUserRole: (uid, role) => dispatch(changeUserRole(uid, role)),
  inviteUser: (user) => dispatch(inviteUser(user)),
  deleteUser: (uid) => dispatch(deleteUser(uid))
});

export default connect(mapState, mapDispatch)(Users);
