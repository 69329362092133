import { createSlice } from '@reduxjs/toolkit';

import { initAccounts } from './accounts';
import { setUiFlag } from './ui';
import { initLabels } from './labels';
import { updateAccountLabels } from '../../functions';

const activeAccount = createSlice({
  name: 'activeAccount',
  initialState: {},
  reducers: {
    setActiveAccount: (state, action) => {
      if (!action.payload.labels) return {...action.payload, labels: []};
      return action.payload;
    },

    toggleActiveLabel: (state, action) => {
      let labels = [...state.labels];
      const index = labels.indexOf(action.payload);

      if (index === -1) {
        labels.push(action.payload);
      } else {
        labels.splice(index, 1);
      }
      return { ...state, labels };
    }
  },
  extraReducers: {
    [initAccounts]: (state, action) => {
      if (action.payload && action.payload.length) {
        const accountExists = action.payload.findIndex(a => a.apiPath === state.apiPath);
        if (!state || accountExists === -1) return action.payload[0];
      }
      return state;
    },

    [initLabels]: (state, action) => {
      let labels = state.labels;
      if (!labels || labels.length === 0) {
        return { ...state, labels: [...action.payload.map(l => l.id), -1] };
      }
    },
  }
});

const updateActiveLabels = () => async (dispatch, getState) => {
  let state = getState();
  if (!state.activeAccount) return;

  try {
    dispatch(setUiFlag({ key: 'dataIsFetching', value: true }));
    const res = await updateAccountLabels(state.activeAccount.labels, state.activeAccount.apiPath);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  } finally {
    dispatch(setUiFlag({ key: 'dataIsFetching', value: false }));
  }
};


const { setActiveAccount, toggleActiveLabel } = activeAccount.actions;
export { setActiveAccount, updateActiveLabels, toggleActiveLabel };
export default activeAccount.reducer;