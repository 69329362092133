import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import TagManager from 'react-gtm-module';

import { createFilters } from '../../functions';
import Tabs from '../../components/Tabs/Tabs';
import OrganizationsTab from "./Organizations";
import Groups from './Groups';
import Users from './Users';
import PageHeader from '../../components/PageHeader/PageHeader';
import useRunCalculations from '../../hooks/useRunCalculations';

import './Home.scss';

const Home = ({
  activeAccount,
  dataIsFetching,
  currentUser,
  isAdmin
}) => {
  const [runCalculations] = useRunCalculations();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const subtabs = [
    { title: "Groups", component: <Groups /> },
    { title: "Organizations", component: <OrganizationsTab /> },
    { title: "Users", component: <Users /> },
  ];

  useEffect(() => {
    if (activeAccount && currentUser) {
      /*console.log('!!!Sending data!!!', {
        userId: currentUser.uid,
        accountId: activeAccount.name,
        acountName: activeAccount.apiPath,
        systemAdministrator: isAdmin
      })*/
      TagManager.dataLayer({
        dataLayer: {
          event: 'AccountChange',
          userId: currentUser.uid,
          accountId: activeAccount.apiPath,
          accountName: activeAccount.name,
          systemAdministrator: isAdmin
        },
        //dataLayerName: 'UserData'
      })
    }
  }, [activeAccount]);

  

  return <>
    <PageHeader
      title="CRM Overview"
      showUserSelect={true}
      onRunCalculations={async (e) => {
        await runCalculations(activeAccount.apiPath);
      }}
      onCreateFilters={async (e) => {
        await createFilters(activeAccount.apiPath);
      }}
      showKpis={true} />

    <div className="content">
      {(dataIsFetching || requestInProgress) && <div className="loader"><Spin /></div>}
      <Tabs subtabs={subtabs} />
    </div>
  </>
}

const mapState = (state) => ({
  labels: state.labels,
  activeAccount: state.activeAccount,
  currentUser: state.currentUser,
  isAdmin: state.isAdmin,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(Home);