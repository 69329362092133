import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Popconfirm, message, Empty, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { fetchWebhooks, deleteWebhook, createWebhooks } from '../../store/slices/webhooks';

const Webhooks = ({
  webhooks,
  fetchWebhooks,
  deleteWebhook,
  activeAccount,
  createWebhooks
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    //{ title: 'Title', dataIndex: 'title' },
    { title: 'Object', dataIndex: 'event_object' },
    { title: 'Action', dataIndex: 'event_action' },
    { title: 'Last delivery', dataIndex: 'last_delivery_time', render: (date) => {
      if (!date) return 'none';

      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    }},
    { title: 'Last status', dataIndex: 'last_http_status' },
    {
      title: '',
      render: (txt, webhook) => <Popconfirm
        title="Are you sure you want to delete webhook?"
        onConfirm={async () => {
          setIsLoading(true);
          try {
            await deleteWebhook(activeAccount.apiPath, webhook.id);
            message.success('Webhook removed successfully');
          } catch (err) {
            message.error('Error removing webhook');
          }
          setIsLoading(false);
        }}
        okText="Yes"
        cancelText="No">
        <a className="table__action-link table__action-link_warning"><DeleteOutlined />&nbsp;Delete</a>
      </Popconfirm>
    }
  ];

  useEffect(() => {
    const initWebhooks = async () => {
      setIsLoading(true);
      await fetchWebhooks(activeAccount.apiPath);
      setIsLoading(false);
    }

    if (!webhooks.length || activeAccount) initWebhooks();
  }, [activeAccount.apiPath]);

  console.log(webhooks);

  return (<>
    <div className="action-btns">
      <Button type="primary" className="invite-btn" onClick={async () => {
        await createWebhooks(activeAccount.apiPath);
        await fetchWebhooks(activeAccount.apiPath);
      }}>Create all webhooks</Button>
    </div>
    <Table
      locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No webhooks" /> }}
      pagination={false}
      className="table"
      loading={isLoading}
      rowKey={i => i.id}
      columns={columns}
      dataSource={webhooks} />
  </>);
};

const mapState = (state) => ({
  webhooks: state.webhooks,
  activeAccount: state.activeAccount
});

const mapDispatch = (dispatch) => ({
  fetchWebhooks: (account) => dispatch(fetchWebhooks(account)),
  deleteWebhook: (account, id) => dispatch(deleteWebhook(account, id)),
  createWebhooks: (account) => dispatch(createWebhooks(account))
});

export default connect(mapState, mapDispatch)(Webhooks);
