import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Form, message, Tooltip, Radio, Select } from 'antd';

//import { addNewAccount, updateAccount } from '../../store/slices/accounts';
//import UserAutoComplete from '../../components/UserAutoComplete/UserAutoComplete';
//import useRunCalculations from '../../hooks/useRunCalculations';

//import * as consts from '../../consts';

const { Option } = Select;

const SelectOwnerModal = ({
  onCancel,
  accountUsers,
  onSelect
}) => {
  const [selectedUser, setSelectedUser] = useState(null);

  const onSubmit = useCallback(() => {
    onSelect(selectedUser);
  }, [selectedUser]);

  const closeModal = useCallback(() => {
    onCancel();
  });

  return (
    <Modal
      className=""
      title={"Select new owner"}
      visible={true}
      confirmLoading={false}
      cancelText="Cancel"
      okText={"Select"}
      onOk={onSubmit}
      onCancel={closeModal}>
      
      <Select defaultValue={accountUsers.length ? accountUsers[0].name : 'Select user'} style={{ width: '100%' }} 
        value={selectedUser || 'Select user'}
        onChange={(id, user) => {
          setSelectedUser(id)
        }}>
        {accountUsers.map(user => <Option value={user.id} key={user.id}>{user.name}</Option>)}
      </Select>
    </Modal>
  );
}

const mapState = (state) => ({
  accountUsers: state.accountUsers.list,
  accounts: state.accounts,
  //systemUsers: state.systemUsers
});

const mapDispatch = (dispatch) => ({
  
});

export default connect(mapState, mapDispatch)(SelectOwnerModal);