import { createSlice } from '@reduxjs/toolkit';
import { getFn } from '../../functions';
import { setActiveAccount } from './activeAccount'

const initialState = { list: [], activeUser: null };

const accountUsers = createSlice({
  name: 'accountUsers',
  initialState,
  reducers: {
    initAccountUsers: (state, action) => {
      if (action.payload) {
        return { ...state, list: action.payload };
      }
      return state;
    },
    setActiveUser: (state, action) => {
      let value = null;
      if (action.payload) {
        if (action.payload && action.payload !== 'all') value = action.payload;
        return { ...state, activeUser: value };
      }
      return state;
    }
  },
  extraReducers: {
    [setActiveAccount]: (state, action) => initialState,
  }
});

const fetchAccountUsers = (account) => async (dispatch, getState) => {
  let state = getState();
  try {
    const fn = getFn('getAccountUsers');
    const res = await fn({ account });
    
    if (res.data && res.data.length) dispatch(accountUsers.actions.initAccountUsers(res.data));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};


const { initAccountUsers, setActiveUser } = accountUsers.actions;
export { initAccountUsers, fetchAccountUsers, setActiveUser };

export default accountUsers.reducer;