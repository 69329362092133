import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Tabs from '../../components/Tabs/Tabs';
import Labels from './Labels';
import Webhooks from './Webhooks';
import AccountAdmins from './AccountAdmins';
import PageHeader from '../../components/PageHeader/PageHeader';

const Settings = ({
  activeAccount,
  isAdmin
}) => {
  const isAccountAdmin = !!(activeAccount && activeAccount.users);

  if (!isAccountAdmin && !isAdmin) return <Redirect to='/' />;

  const subtabs = [
    { title: "Users", component: <AccountAdmins /> },
    { title: "Labels", component: <Labels /> },
    { title: "Webhooks", component: <Webhooks /> },
  ];

  return <>
    <PageHeader
      title="Account settings"
    />
    <Tabs subtabs={subtabs} />
  </>;
}

const mapState = (state) => ({
  //accounts: state.accounts,
  activeAccount: state.activeAccount,
  isAdmin: state.isAdmin,
});

export default connect(mapState, {})(Settings);