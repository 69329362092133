import { createSlice } from '@reduxjs/toolkit';

const currentUser = createSlice({
  name: 'currentUser',
  initialState: '',
  reducers: {
    initCurrentUser: (state, action) => action.payload,
  },
});


export const { initCurrentUser } = currentUser.actions;

export default currentUser.reducer;