import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Table, message, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Button from '../../components/Buttons/Button';
import AccountModal from './AccountModal';
import { deleteAccount } from '../../store/slices/accounts';

const AccountsTab = ({
  accounts,
  deleteAccount
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const onDeleteAccount = useCallback(async (accountId) => {
    setIsLoading(true);
    try {
      await deleteAccount(accountId);
      message.success('Account succesfully removed');
    } catch (err) {
      message.error('Error removing account')
    }
    setIsLoading(false);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsVisible(false);
    setEditingItem(null);
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%'
    },
    {
      title: '',
      render: (txt, account) => <a className="table__action-link"
        onClick={() => {
          setEditingItem(account);
          setModalIsVisible(true);
        }}><EditOutlined />&nbsp;Edit</a>,
      width: 90
    },
    {
      title: '',
      render: (txt, account) => <Popconfirm
        title="Are you sure you want to delete this account?"
        onConfirm={() => onDeleteAccount(account.apiPath)}
        okText="Yes"
        cancelText="No">
        <a className="table__action-link table__action-link_warning"><DeleteOutlined />&nbsp;Delete</a>
      </Popconfirm>
    }
  ];

  return (<>
    <div className="action-btns">
      <Button type="primary" className="invite-btn" onClick={() => {
        setModalIsVisible(true);
      }}>Add new account</Button>
    </div>

    <Table
      pagination={false}
      className="table"
      loading={isLoading}
      columns={columns}
      dataSource={accounts}
      rowKey="apiPath" />

    {modalIsVisible && 
      <AccountModal onCancel={closeModal} editingItem={editingItem} />
    }
  </>
  );
}

const mapState = (state) => ({
  accounts: state.accounts,
});

const mapDispatch = (dispatch) => ({
  deleteAccount: (accountId) => dispatch(deleteAccount(accountId))
});

export default connect(mapState, mapDispatch)(AccountsTab);