import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import get from "lodash/get";

import { fetchAccountUsers, setActiveUser } from '../../store/slices/accountUsers';
import { filterOrganizationsByUser } from '../../store/slices/organizations';

const Users = ({
  users,
  fetchAccountUsers,
  activeAccount,
  setActiveUser,
  filterOrganizationsByUser,
  kpis
}) => {
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      await fetchAccountUsers(activeAccount.apiPath);
      setIsLoading(false);
    }
    (!users || !users.length) && init();
  }, [activeAccount]);
  
  console.log(users);
  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => {
        const an = a.name ? a.name : '';
        const bn = b.name ? b.name : '';
        return bn.localeCompare(an);
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => b.email.localeCompare(a.email),
    },
    {
      title: 'Activity effectiveness',
      render: (user) => {
        const value = get(kpis, ['activityEffectiveness', 'perUser', user.id, 'value']);
        const dividend = get(kpis, ['activityEffectiveness', 'perUser', user.id, 'dividend']);
        const divider = get(kpis, ['activityEffectiveness', 'perUser', user.id, 'divider']);
        if (isNaN(value)) return '';
        return <>{value}% <small>{`${dividend || 0} / ${divider || 0}`}</small></>;
      }
    },
    {
      title: 'Planning effectiveness',
      render: (user) => {
        const value = get(kpis, ['planningEffectiveness', 'perUser', user.id, 'value']);
        const dividend = get(kpis, ['planningEffectiveness', 'perUser', user.id, 'dividend']);
        const divider = get(kpis, ['planningEffectiveness', 'perUser', user.id, 'divider']);
        if (isNaN(value)) return null;
        return <>{value}% <small>{`${dividend || 0} / ${divider || 0}`}</small></>;
      }
    },
    {
      title: 'CRM performance',
      render: (user) => {
        const ae = get(kpis, ['activityEffectiveness', 'perUser', user.id, 'value']);
        const pe = get(kpis, ['planningEffectiveness', 'perUser', user.id, 'value']);
        if (isNaN(ae) || isNaN(pe)) return null;
        console.log(ae, pe)
        return Math.round((1*ae + 1*pe) / 2) + '%';
      }
    }
  ];

  return (<>
    <Table
      pagination={{ defaultPageSize: 50, pageSizeOptions: [50, 100, 250, 500, 1000] }}
      className="table table_clickable"
      onRow={(u, rowIndex) => ({
        onClick: (e) => {
          setActiveUser(u.id);
          filterOrganizationsByUser(u.id);
        }
      })}
      loading={isLoading}
      rowKey={i => i.id}
      columns={columns}
      dataSource={users} />
  </>);
}

const mapState = (state) => ({
  users: state.accountUsers.list,
  activeAccount: state.activeAccount,
  kpis: state.kpis
});

const mapDispatch = (dispatch) => ({
  fetchAccountUsers: (account) => dispatch(fetchAccountUsers(account)),
  setActiveUser: (id) => dispatch(setActiveUser(id)),
  filterOrganizationsByUser: (id) => dispatch(filterOrganizationsByUser(id))
});

export default connect(mapState, mapDispatch)(Users);