import React, { useEffect, useState, useRef }  from 'react';
import { connect } from 'react-redux';
import { Table, Switch, message } from 'antd';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import Button from '../../components/Buttons/Button';
import { fetchLabels, resetLabels } from '../../store/slices/labels';
import { updateActiveLabels, toggleActiveLabel } from '../../store/slices/activeAccount';

import useDidMountEffect from '../../hooks/useDidMountEffect';
import useRunCalculations from '../../hooks/useRunCalculations';

const LabelsTab = ({
  labels,
  activeAccount,
  activeLabels,
  fetchLabels,
  resetLabels,
  updateActiveLabels,
  toggleActiveLabel,
  dataIsFetching,
  organizations,
}) => {
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emptyLabel, setEmptyLabel] = useState({});
  const defaultActiveLabels = useRef(activeLabels);
  const [runCalculations] = useRunCalculations();

  useEffect(() => {
    const init = async () => {
      await fetchLabels();
    };
    init();
  }, []);

  // when account is changed in drawer - refetch labels
  useDidMountEffect(() => {
    resetLabels();
  }, [activeAccount.apiPath]);


  // once labels were refetched on account change, update refs
  useEffect(() => {
    defaultActiveLabels.current = activeLabels;
  }, [labels]);

  // set an empty "catch all" label, when organizations change
  useEffect(() => {
    if (!organizations.length) return;
    
    console.log('orgs changed');
    const init = async () => {
      await fetchLabels();
    }
    init();

    const orgsWithoutLabel = organizations.filter(o => !o.label);
    setEmptyLabel({
      id: -1,
      label: 'No label',
      numOfOrgs: orgsWithoutLabel.length
    });
  }, [organizations]);

  // check if active labels were changed
  useEffect(() => {
    if (dataIsFetching) {
      setIsChanged(false);
      return;
    }
    
    if (isEqual(sortBy(activeLabels), sortBy(defaultActiveLabels.current))) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  });

  let labelsWithEmpty = [];
  if (labels.length) {
    labelsWithEmpty = [emptyLabel, ...labels];
  }

  const onRowClick = async (id) => {
    let isChecked = activeLabels.indexOf(id) !== -1;
    if (activeLabels.length === 1 && isChecked) {
      message.warning('At least one label should be selected');
      return false;
    }
    toggleActiveLabel(id);
  }
  
  const columns = [
    { 
      title: 'Enabled', 
      dataIndex: 'enabled',
      width: 90,
      render: (text, label) => {
        let checked = (activeLabels.indexOf(label.id) !== -1);
        return <Switch checked={checked} onChange={(value) => onRowClick(label.id)} />
      }
    }, 
    { 
      title: 'Title', 
      dataIndex: 'label',
      render: (text, label) => {
        if (label.id === -1) return <i>{text}</i>;
        return text;
      }
    }, 
    {
      title: '# of Organizations',
      dataIndex: 'numOfOrgs',
    }
  ];
  
  return (<>
    {isChanged && <div className="action-btns">
      <Button type="primary" onClick={async () => {
        try {
          setIsLoading(true);
          await updateActiveLabels();
          setIsLoading(false);
          await runCalculations(activeAccount.apiPath);
        } catch(err) {
          console.log(err);
        }
      }} className="invite-btn">Update Settings and run calculations</Button>
    </div>}

    <Table 
      pagination={false}
      onRow={(label, rowIndex) => ({
        onClick: (e) => {
          if (e.target.getAttribute('role') !== 'switch') {
            onRowClick(label.id);
          }
        },
      })}
      className="table table_clickable table_labels"
      loading={(dataIsFetching || isLoading)}
      rowKey={i => i.id}
      columns={columns}
      dataSource={labelsWithEmpty}
      rowKey="id"
      title={() => "Disable labels you want to exclude in calculations. Organisations with a disabled label will be registered as opted out by our algorithm."} />
    </>
  );
}

const mapState = (state) => ({
  labels: state.labels,
  activeLabels: state.activeAccount.labels,
  activeAccount: state.activeAccount,
  dataIsFetching: state.ui.dataIsFetching,
  organizations: state.organizations.all
});

const mapDispatch = (dispatch) => ({
  fetchLabels: () => dispatch(fetchLabels()),
  resetLabels: () => dispatch(resetLabels()),
  updateActiveLabels: () => dispatch(updateActiveLabels()),
  toggleActiveLabel: (labelId) => dispatch(toggleActiveLabel(labelId)),
});

export default connect(mapState, mapDispatch)(LabelsTab);