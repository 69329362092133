import { createSlice } from '@reduxjs/toolkit';
import uniq from "lodash/uniq";

import { getOrganizationFields } from '../../functions';
import { setUiFlag } from './ui';
import * as consts from '../../consts';

const labels = createSlice({
  name: 'labels',
  initialState: [],
  reducers: {
    initLabels: (state, action) => {
      if (action.payload) return action.payload;
      return [];
    },

    resetLabels: (state, action) => [],
    
    toggleLocalStateLabel: (state, action) => {
      const index = state.indexOf(action.payload);
      if (index !== -1) {
        state.push(action.payload);
      } else {
        state.splice(index, 1);
      }
      return state;
    }
  },
});

const fetchLabelsPipedrive = async (orgs, apiPath) => {
  const res = await getOrganizationFields(apiPath);
  const labelField = res.find(field => field.key === 'label');
  let list = [];

  if (labelField && labelField.options) {
    list = labelField.options;
  }

  list = list.map(label => {
    let res = orgs.filter(o => o.label === label.id);
    return { ...label, numOfOrgs: (res ? res.length : 0) };
  });

  list = list.sort(function (a, b) {
    let textA = a.label.toUpperCase();
    let textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  return list;
};

const fetchLabelsWebcrm = (orgs) => {
  let list = [];
  console.log(orgs.length);
  orgs.forEach(o => {
    const label = o.label;
    if (!label) return;
    const index = list.findIndex(i => i.label === label);

    if (index === -1) {
      list.push({
        label,
        id: label,
        numOfOrgs: 1
      });
    } else {
      list[index].numOfOrgs += 1;
    }
  });
  console.log(list);
  return list;
};


const fetchLabels = () => async (dispatch, getState) => {
  let state = getState();
  if (!state.activeAccount) return;

  const orgs = state.organizations.all;
  const account = state.activeAccount;

  try {
    let res;
    if (account.type === consts.ACC_TYPE_PIPEDRIVE) {
      dispatch(setUiFlag({ key: 'dataIsFetching', value: true }));
      res = await fetchLabelsPipedrive(orgs, account.apiPath);  
    } else {
      res = fetchLabelsWebcrm(orgs);
    }
    dispatch(labels.actions.initLabels(res));
    return res;

  } catch (err) {
    console.log(err);
    return err;
  } finally {
    dispatch(setUiFlag({ key: 'dataIsFetching', value: false }));
  }
};

const { initLabels, resetLabels } = labels.actions;
export { initLabels, fetchLabels, resetLabels };

export default labels.reducer;