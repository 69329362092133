import React, { useRef } from 'react';
import { getFn } from '../functions';

//const interval = 120000;
const interval = 600000;

const useUpdatesChecker = () => {
  const intervalHandler = useRef(null);

  const checkForUpdates = async (timestamp, account) => {
    try {
      const fn = getFn('checkForUpdates');
      const res = await fn({ timestamp, account });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const initChecker = async (timestamp, account, callback) => {
    if (intervalHandler.current) clearInterval(intervalHandler.current);

    intervalHandler.current = setInterval(async () => {
      const res = await checkForUpdates(timestamp, account);
      if (res) {
        console.log(res);
        clearInterval(intervalHandler.current);
        callback();
      }
    }, interval);
  };

  return [initChecker];
}

export default useUpdatesChecker;