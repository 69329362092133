import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Tabs from '../../components/Tabs/Tabs';
import Accounts from './Accounts';
import SystemUsers from './SystemUsers';
import PageHeader from '../../components/PageHeader/PageHeader';

const Settings = ({
  isAdmin
}) => {
  if (!isAdmin) return <Redirect to='/' />;
  
  const subtabs = [
    { title: "Users", component: <SystemUsers /> },
    { title: "Accounts", component: <Accounts /> },
  ];

  return <>
    <PageHeader
      title="System settings"
    />
    <Tabs subtabs={subtabs} />
  </>;
}

const mapState = (state) => ({
  isAdmin: state.isAdmin,
});

export default connect(mapState, {})(Settings);