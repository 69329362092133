import React from 'react';
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module';

import App from './App';
import * as serviceWorker from './serviceWorker';
import FirebaseContext, { firebaseApp } from './FirebaseContext';
import store, { persistor } from './store/store';

TagManager.initialize({
  gtmId: "GTM-5KZ8JQS",
  //dataLayerName: 'UserData',
  events: {
    AccountChange: 'Account Change',
  }
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <FirebaseContext.Provider value={firebaseApp}>
        <App />
      </FirebaseContext.Provider>
    </PersistGate>
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
