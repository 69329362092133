import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Table, Checkbox, Popconfirm, message, Empty } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import Button from '../../components/Buttons/Button';
import { fetchAccountAdmins, changeUserRole, inviteUser, deleteUser, addUserToaccount } from '../../store/slices/accountAdmins';
import InviteUserModal from '../../components/InviteUserModal/InviteUserModal';

const Users = ({
  users,
  fetchAccountAdmins,
  changeUserRole,
  addUserToaccount,
  deleteUser,
  currentUser,
  activeAccount
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inviteModalIsVisible, setInviteModalIsVisible] = useState(false);

  const columns = [
    { title: 'Email', dataIndex: 'email' }, 
    { title: 'Name', dataIndex: 'displayName' },
    {
      title: 'Account administrator',
      dataIndex: 'accountAdmin',
      render: (text, user) => (
        <Checkbox onChange={async (e) => {
            let newRole = (user.role === 'user' ? 'admin' : 'user');
            setIsLoading(true);
            await changeUserRole(user.uid, newRole)
            setIsLoading(false);
          }}
          checked={user.role === 'admin'} 
        />
      )
    },
    {
      title: '',
      render: (txt, user) => ((user.uid !== currentUser.uid && (!user.customClaims || user.customClaims.role !== 'admin')) ? <Popconfirm
        title="Are you sure you want to delete user from account?"
        onConfirm={async () => {
          setIsLoading(true);
          try {
            await deleteUser(user.uid);
            message.success('User access to account retrieved successfully');
          } catch (err) {
            message.error('Error removing user access');
          }
          setIsLoading(false);
        }}
        okText="Yes"
        cancelText="No">
        <a className="table__action-link table__action-link_warning"><DeleteOutlined />&nbsp;Delete</a>
      </Popconfirm> : null)
    }
  ];

  useEffect(() => {
    const initUsers = async () => {
      setIsLoading(true);
      await fetchAccountAdmins();
      setIsLoading(false);
    }

    if (!users.length || activeAccount) initUsers();
  }, [activeAccount.apiPath]);

  return (<>
      <div className="action-btns">
        <Button type="primary" className="invite-btn" onClick={() => setInviteModalIsVisible(true)}>Invite new user</Button>
      </div>

      <Table 
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No users" /> }}
        pagination={false}
        className="table"
        loading={isLoading}
        rowKey={i => i.uid}
        columns={columns}
        dataSource={users} />

      {inviteModalIsVisible &&
        <InviteUserModal 
          onCancel={() => setInviteModalIsVisible(false)} 
          onInvite={async (values) => {
            setIsLoading(true);
            await addUserToaccount(values);
            setIsLoading(false);
          }} 
          inviteToAccount={true} />
      }
    </>
  );
};

const mapState = (state) => ({
  users: state.accountAdmins,
  currentUser: state.currentUser,
  activeAccount: state.activeAccount
});

const mapDispatch = (dispatch) => ({
  fetchAccountAdmins: () => dispatch(fetchAccountAdmins()),
  changeUserRole: (uid, role) => dispatch(changeUserRole(uid, role)),
  deleteUser: (uid) => dispatch(deleteUser(uid)),
  addUserToaccount: (user) => dispatch(addUserToaccount(user))
});

export default connect(mapState, mapDispatch)(Users);
