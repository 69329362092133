import React, { useEffect, useState, useCallback } from 'react';
import { AutoComplete } from 'antd';

const { Option } = AutoComplete;

const isEmail = (e) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e);

const UserAutoComplete = ({
  suggestions,
  onSelect,
  onChange
}) => {
  const [options, setOptions] = useState([]);

  const onSearch = useCallback((search) => {
    const res = suggestions.filter(u => u.email.indexOf(search) !== -1);
    setOptions(res);
  }, []);

  useEffect(() => {
    suggestions.length > 0 && setOptions(suggestions);
  }, [suggestions]);

  const autoCompleteOptions = options.map((u) => (
    <Option key={u.uid} value={u.email}>
      {u.email}
    </Option>
  ));

  return <AutoComplete
    style={{ width: '100%' }}
    onSearch={onSearch}
    onChange={(value, record) => {
      if (!isEmail(value)) return;
      onChange(value);

      if (!record.key) {
        onSelect({ value, isExistingUser: false });
      } else {
        onSelect({ value, isExistingUser: true });
      }
    }}
    placeholder="Search for user email">
    {autoCompleteOptions}
  </AutoComplete>;
}

export default UserAutoComplete;