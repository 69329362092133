import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';


import accounts from './slices/accounts';
import organizations from './slices/organizations';
import kpis from './slices/kpis';
import filters from './slices/filters';
import labels from './slices/labels';
import activeAccount from './slices/activeAccount';
import accountAdmins from './slices/accountAdmins';
import accountUsers from './slices/accountUsers';
import systemUsers from './slices/systemUsers';
import ui from './slices/ui';
import isAdmin from './slices/isAdmin';
import currentUser from './slices/currentUser';
import webhooks from './slices/webhooks';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['activeAccount']
}

const middleware = [...getDefaultMiddleware({
  serializableCheck: { 
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] 
  } 
}), logger];

const appReducer = combineReducers({
  activeAccount,
  accounts,
  organizations,  
  kpis,
  labels,
  filters,
  accountAdmins,
  systemUsers,
  ui,
  isAdmin,
  currentUser,
  accountUsers,
  webhooks
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    // storage.removeItem('persist:otherKey')
    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({ reducer: persistedReducer, middleware });
const persistor = persistStore(store);

export default store;
export { persistor };