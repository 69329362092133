import { createSlice } from '@reduxjs/toolkit';

import { getKpis } from '../../functions';
import { setActiveAccount } from './activeAccount';

const kpis = createSlice({
  name: 'kpis',
  initialState: {},
  reducers: {
    initKpis: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: {
    [setActiveAccount]: (state, action) => {
      return {};
    }
  }
});

const fetchKpis = () => async (dispatch, getState) => {
  let state = getState();
  if (!state.activeAccount) return;

  try {
    const res = await getKpis(state.activeAccount.apiPath);
    dispatch(kpis.actions.initKpis(res));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export { fetchKpis };

export default kpis.reducer;