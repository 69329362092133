import firebase from 'firebase/app';

const getFn = (name) => firebase.functions().httpsCallable(name);

const runCalculations = async (account) => {
  const fn = firebase.functions().httpsCallable('runCalculations');
  
  try {
    const res = await fn({ account });  
    return res.data[0];
  } catch(err) {
    throw new Error(err);
  }
};

const getAllFilters = async (account) => {
  const fn = firebase.functions().httpsCallable('getAllFilters');
  try {
    const res = await fn({ account });
    return res.data;
  } catch(err) {
    throw new Error(err);
  }
};

const createFilters = async (account) => {
  const fn = firebase.functions().httpsCallable('createFilters');
  try {
    const res = await fn({ account });
    return true;
  } catch(err) {
    console.log(err);
  }
};

const getOrganizations = async (account) => {
  const fn = firebase.functions().httpsCallable('getOrganizations');
  try {
    const res = await fn({ account });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getKpis = async (account) => {
  const fn = firebase.functions().httpsCallable('getKpis');
  try {
    const res = await fn({ account });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};


const getUserAccounts = async () => {
  const fn = firebase.functions().httpsCallable('getUserAccounts');
  try {
    const res = await fn({ });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getOrganizationFields = async (account) => {
  const fn = firebase.functions().httpsCallable('getOrganizationFields');
  try {
    const res = await fn({ account });
    return res.data
  } catch (err) {
    throw new Error(err);
  }
};

const toggleAccountLabel = async (labelId, account) => {
  const fn = firebase.functions().httpsCallable('toggleAccountLabel');
  try {
    const res = await fn({ account, labelId });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
}

const updateAccountLabels = async (labels, account) => {
  const fn = firebase.functions().httpsCallable('updateAccountLabels');
  try {
    const res = await fn({ account, labels });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }  
}

const getAllUsers = async () => {
  const fn = firebase.functions().httpsCallable('getAllUsers');
  try {
    const res = await fn({});
    return res. data;
  } catch(err) {
    throw new Error(err);
  }
};

const getAccountAdmins = async (account) => {
  const fn = firebase.functions().httpsCallable('getAccountAdmins');
  try {
    const res = await fn({ account });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const changeAccountUserRole = async (account, uid, role) => {
  const fn = firebase.functions().httpsCallable('changeAccountUserRole');
  try {
    const res = await fn({ account, uid, role });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const changeSystemUserRole = async (uid, role) => {
  const fn = firebase.functions().httpsCallable('changeSystemUserRole');
  try {
    const res = await fn({ uid, role });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const createAccount = async (data) => {
  const fn = firebase.functions().httpsCallable('createAccount');
  try {
    const res = await fn({ data });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const deleteAccount = async (accountId) => {
  const fn = firebase.functions().httpsCallable('deleteAccount');
  try {
    const res = await fn({ accountId });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const updateAccount = async (accountId, values) => {
  const fn = firebase.functions().httpsCallable('updateAccount');
  try {
    const res = await fn({ accountId, values });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const inviteUser = async (user, account) => {
  const fn = firebase.functions().httpsCallable('inviteUser');
  let params = { ...user }; 
  if (account) params.account = account;
  try {
    const res = await fn(params);
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const deleteUser = async (uid) => {
  const fn = firebase.functions().httpsCallable('deleteUser');
  try {
    const res = await fn({ uid });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

const deleteUserFromAccount = async (uid, account) => {
  const fn = firebase.functions().httpsCallable('deleteUserFromAccount');
  try {
    const res = await fn({ uid, account });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export { 
  getFn,
  runCalculations, 
  getAllFilters, 
  createFilters, 
  getOrganizations, 
  getKpis, 
  getUserAccounts, 
  getOrganizationFields,
  toggleAccountLabel,
  getAllUsers,
  getAccountAdmins,
  updateAccountLabels,
  changeAccountUserRole,
  changeSystemUserRole,
  createAccount,
  deleteAccount,
  updateAccount,
  inviteUser,
  deleteUser,
  deleteUserFromAccount
};