import React from 'react';
import { useState } from 'react-redux';
import { ACC_TYPE_PIPEDRIVE, ACC_TYPE_WEBCRM } from '../consts';

const OrgLink = ({ children, id, account }) => {
  if (account.type === ACC_TYPE_WEBCRM) {
    return <span className="org-title">{children}</span>;
  }

  return <a href={`https://${account.apiPath}.pipedrive.com/organization/${id}`} target="_blank"
    className="org-title" rel="noopener noreferrer">{children}</a>;
};

const FilterLink = ({ id, priority, account }) => {
  if (account.type === ACC_TYPE_WEBCRM) {
    return <span className="group-score">{priority}</span>;
  }

  return <a href={`https://${account.apiPath}.pipedrive.com/organizations/list/filter/${id}`} className="group-score" target="_blank" rel="noopener noreferrer"
    onClick={e => { 
      e.stopPropagation(); 
      e.nativeEvent.stopImmediatePropagation(); }
    }>{priority}</a>;
};


export { OrgLink, FilterLink };