import React, { useContext } from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import FirebaseContext from '../../FirebaseContext';

import styles from './UserDropdown.module.scss';

const UserDropdown = ({
  currentUser,
  logout,
  accounts,
  isAdmin,
  activeAccount
}) => {
  const firebaseApp = useContext(FirebaseContext);
  const account = accounts.find(acc => acc.apiPath === activeAccount.apiPath);
  const isAccountAdmin = !!(account && account.users);
  const initials = currentUser.displayName ? currentUser.displayName.split(' ').map(i => i[0]).join('').toUpperCase() : '';
  
  const UserMenu = (
    <Menu>
      <Menu.Item key="logout" className={`${styles['user-menu__link']} ${styles['user-menu__link_alert']}`}
        onClick={() => {
          logout();
          firebaseApp.auth().signOut();
        }}>
        Log Out
      </Menu.Item>
    </Menu>
  );

  return <div className={`${styles['user-menu']} ${(isAccountAdmin || isAdmin) ? styles['user-menu_last'] : ''}`}>
    <Dropdown overlay={UserMenu} trigger={['click']} placement="topCenter">
      <a className={"ant-dropdown-link " + styles['user-menu__dropdown']} onClick={e => e.preventDefault()}>
        {initials.length ? <span className={styles['user-menu__initials']}>{initials}</span> : null}
        <span className={styles['user-menu__name']}>{currentUser && currentUser.email} </span>
        <DownOutlined className={styles['user-menu__arrow']} />
      </a>
    </Dropdown>
  </div>
}

const mapState = (state) => ({
  currentUser: state.currentUser,
  isAdmin: state.isAdmin,
  accounts: state.accounts,
  activeAccount: state.activeAccount
});

const mapDispatch = (dispatch) => ({
  logout: () => dispatch({ type: 'USER_LOGOUT' })
});

export default connect(mapState, mapDispatch)(UserDropdown);