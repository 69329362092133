import { createSlice } from '@reduxjs/toolkit';
import { getFn } from '../../functions';
import { setActiveAccount } from './activeAccount';


const webhooks = createSlice({
  name: 'webhooks',
  initialState: [],
  reducers: {
    initWebhooks: (state, action) => {
      if (action.payload) {
        return action.payload;
      }
      return state;
    },
    deleteWebhook: (state, action) => {
      return state.filter(w => w.id !== action.payload);
    }
  },
  extraReducers: {
    [setActiveAccount]: (state, action) => [],
  }
});

const fetchWebhooks = (account) => async (dispatch, getState) => {
  try {
    const fn = getFn('getAllWebhooks');
    const res = await fn({ account });
    console.log(res);
    if (res.data && res.data.length) dispatch(webhooks.actions.initWebhooks(res.data));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const deleteWebhook = (account, id) => async (dispatch, getState) => {
  try {
    const fn = getFn('removeWebhook');
    const res = await fn({ account, id });
    console.log(res);
    dispatch(webhooks.actions.deleteWebhook(id));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const createWebhooks = (account) => async (dispatch, getState) => {
  try {
    const fn = getFn('createWebhooks');
    const res = await fn({ account });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};


export { fetchWebhooks, deleteWebhook, createWebhooks };
export default webhooks.reducer;