const actions = [
  `Call them now or opt them out`,
  `Call them now or opt them out`,
  `Add open activitites to them or opt them out`,
  `Add open activitites to them or opt them out`,
  `Call them now or opt them out`,
  `Call them now or opt them out`,
  `Add open activitites to them or opt them out`,
  `Add open activitites to them or opt them out`,
  `Call them now or opt them out`,
  `Add activitites to them or opt them out`,
  `Add activitites to them or opt them out`,
  `Add activitites to them or let them stay opted out`,
  `Call them now or opt them out`,
  `Dont do anything`
];

const descriptions = [
  `There are open deals on then, there are open activities on them and they are not overdue yet`,
  `There are open deals on then, there are open activities on them and they are overdue`,
  `There are open deals on then, there are no activitites on them`,
  `There are won deals on then, there are no open activitites on them`,
  `There are lost deals on them, there are open activities on them and they are either overdue or overdue`,
  `There is no deals on them, there are open activities on them and they are either overdue or overdue. Furthermore there is a contactperson present`,
  `There is no deals on them, there are no open activities on them. There is not a contactperson present. But there are old done activities in them.`,
  `There is no deals on them, there are no open activities on them. There is a contactperson present.`,
  `There is no deals on them, there are open activities on them and they are either overdue or overdue. Furthermore there is no contactperson present, and there are no old done activities.`,
  `There are lost deals on them and no open, old done or future activities on them.`,
  `They do not contain any data`,
  `They are opted out`,
  `There are no deals on them, there are either open not overdue or overdue activities on them.`,
  `There are open, future activities on them.`
];

export { actions, descriptions };