import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Form, message } from 'antd';

const InviteUserModal = ({
  onCancel,
  onSubmit
}) => {
  const [form] = Form.useForm();

  const onSubmitModal = useCallback(async (values) => {
    onSubmit(values);
  }, []);

  const closeModal = useCallback(async () => {
    form.resetFields();
    onCancel();
  }, []);

  useEffect(() => {
    
  }, []);

  return (
    <Modal
      className=""
      title={"Create activities"}
      visible={true}
      cancelText="Cancel"
      okText={"Create"}
      onOk={form.submit}
      onCancel={closeModal}>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        initialValues={{}}
        onFinish={onSubmitModal}>
          <Form.Item
            label="Subject"
            name="subject"
            rules={[
              {
                required: true,
                message: 'Please input subject',
              },
            ]}>
            <Input />
          </Form.Item>
      </Form>
    </Modal>
  );
}

const mapState = (state) => ({
  
});

const mapDispatch = (dispatch) => ({
  
});

export default connect(mapState, mapDispatch)(InviteUserModal);