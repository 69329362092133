import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PageHeader, Statistic, Row, Tooltip, Select, Typography, Modal } from 'antd';
import get from 'lodash/get';

import Button from "../Buttons/Button";
import { fetchAccountUsers, setActiveUser } from '../../store/slices/accountUsers';
import { filterOrganizationsByUser } from '../../store/slices/organizations';
import InformationModal from "./InformationModal";

import styles from "./PageHeader.module.scss";

const { Option } = Select;

const { Paragraph } = Typography;

const StatValue = ({ title, value, description, suffix = null }) => {
  return <Tooltip title={description} placement="bottom">
    <span />
    <Statistic 
      className={styles['stat-block']} 
      title={title} 
      value={`${value}%`}
      suffix={suffix} />
  </Tooltip>;
};

const PageHeaderComponent = ({
  onRunCalculations,
  onCreateFilters,
  showUserSelect = false,
  kpis, 
  title,
  showKpis,
  users,
  activeAccount,
  fetchAccountUsers,
  setActiveUser,
  activeUser,
  filterOrganizationsByUser,
  information,
  description
}) => {
  const [infoModalIsVisible, setInfoModalIsVisible] = useState(false);

  const extraBtns = [];
  onRunCalculations && extraBtns.push(<Button key="1" type="primary" onClick={onRunCalculations}>Run Calculations</Button>);
  
  onCreateFilters && extraBtns.push(<Button key="2" type="default" onClick={onCreateFilters}>Create Filters</Button>);
  
  information && extraBtns.push(<Button key="3" type="primary" onClick={() => setInfoModalIsVisible(true)} className={styles['only-btn']}>
    {information.title}
  </Button>)

  showUserSelect && extraBtns.push(<Select key="4"
    style={{ width: 150 }}
    value={activeUser ? activeUser : 'all'}
    onChange={(uid) => {
      setActiveUser(uid);
      filterOrganizationsByUser(uid);
    }}
    placeholder="Select a user">
    <Option value="all" key="all">All users</Option>
    {(users && users.length) ? users.map(u => (<Option value={u.id} key={u.id}>{u.name ? u.name : u.email}</Option>)) : null}
  </Select>);

  useEffect(() => {
    const initUsers = async () => {
      await fetchAccountUsers(activeAccount.apiPath);
    }
    showUserSelect && initUsers();
  }, [activeAccount.apiPath, showUserSelect]);
  
  let ae = null;
  let pe = null;
  let crmPerf = null;
  if (!activeUser)  {
    ae = get(kpis, 'activityEffectiveness');
    pe = get(kpis, 'planningEffectiveness');
    crmPerf = get(kpis, 'crmPerformance');
  } else {
    ae = get(kpis, ['activityEffectiveness', 'perUser', activeUser]);
    pe = get(kpis, ['planningEffectiveness', 'perUser', activeUser]);
    if (ae && pe && ae.value && pe.value) {
      crmPerf = Math.round((1 * ae.value + 1 * pe.value) / 2);
    } else {
      crmPerf = null;
    }
  }

  //console.log(ae, pe, crmPerf);

  return <div className={styles.header}>
    <PageHeader
      title={title}
      extra={extraBtns}>
      {showKpis ? <Row className={styles['stat-row']}>
        {ae ? <StatValue title="Activity effectiveness" 
            value={ae.value} 
            suffix={`${ae.dividend || 0} / ${ae.divider || 0}`}
            description="Can you keep up in your CRM? - Percentage of open activities that are NOT overdue (100% is best)" /> : null}
          
        {pe ? <StatValue title="Planning effectiveness" 
            value={pe.value}
            suffix={`${pe.dividend || 0} / ${pe.divider || 0}`}
            description="Are you planning activities in your CRM? - Percentage of organisations with open activities (100% is best)" /> : null}
            
        {crmPerf ? <StatValue title="CRM performance" 
            value={crmPerf}
            description="The average of planning effectiveness and activity effectiveness" /> : null}
      </Row> : null}

      {description ? <Row>
        <Paragraph>{description}</Paragraph>
      </Row> : null}
    </PageHeader>

    {infoModalIsVisible && <InformationModal {...information} onClose={() => setInfoModalIsVisible(false)} />}
  </div>
};

const mapState = (state) => {
  return {
    kpis: state.kpis,
    users: state.accountUsers.list,
    activeAccount: state.activeAccount,
    activeUser: state.accountUsers.activeUser
  }
};

const mapDispatch = (dispatch) => ({
  fetchAccountUsers: (account) => dispatch(fetchAccountUsers(account)),
  setActiveUser: (id) => dispatch(setActiveUser(id)),
  filterOrganizationsByUser: (id) => dispatch(filterOrganizationsByUser(id))
});

export default connect(mapState, mapDispatch)(PageHeaderComponent);