import firebase from 'firebase/app';

/* fetch api functions no longer in use, using firebase functions instead */
const getAllAccounts = () => {
  const ref = firebase.database().ref('accounts');
  return ref.once('value').then((snapshot) => {
    let res = [];
    snapshot.forEach(child => {
      res.push(child.val());
    });
    return res;
  });
};

const getOrganizations = (account) => {
  const ref = firebase.database().ref(`organizations/${account}`).orderByChild('name');
  return ref.once('value').then((snapshot) => {
    let res = [];
    snapshot.forEach((child) => {
      res.push(child.val());
    });
    return res;
  });
}

const getKpis = (account) => {
  const ref = firebase.database().ref(`kpis/${account}`);
  return ref.once('value').then((snapshot) => snapshot.val());
}

const getOrganizationGroups = (orgs) => {
  let groups = [];

  orgs.forEach(o => {
    if (o.priority === undefined || o.priority === null) return;
    
    let group = groups.find(g => g.priority === o.priority);
    if (group) {
      group.orgs.push(o);
    } else {
      groups.push({
        priority: o.priority,
        orgs: [o],
        key: o.priority
      });
    }
  });
  
  for (let i = 1; i <= 14; i++) {
    if (groups.findIndex(g => (1*g.priority === i)) === -1) {
      groups.push({ priority: i, orgs: [], key: i });
    }
  }

  return groups.sort((a, b) => {
    if ((1 * a.priority) > (1 * b.priority)) {
      return 1;
    }
    return -1;
  });
};

export { getOrganizations, getOrganizationGroups, getKpis, getAllAccounts };