import { createSlice } from '@reduxjs/toolkit';

import { getAllFilters } from '../../functions';
import { setActiveAccount } from './activeAccount';

const filters = createSlice({
  name: 'filters',
  initialState: [],
  reducers: {
    initFilters: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: {
    [setActiveAccount]: (state, action) => {
      return [];
    }
  }
});

const fetchFilters = () => async (dispatch, getState) => {
  let state = getState();
  if (!state.activeAccount) return;

  try {
    const res = await getAllFilters(state.activeAccount.apiPath);
    dispatch(filters.actions.initFilters(res));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
}

//const { initFilters } = filters.actions;

export { fetchFilters };

export default filters.reducer;