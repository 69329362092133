import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import uniq from 'lodash/uniq';
import { OrgLink } from '../../components/Links';

const actions = [
  'Call',
  'Call',
  'Plan',
  'Plan',
  'Call',
  'Call',
  'Plan',
  'Call',
  'Plan',
  'Plan',
  'Opt them in',
  'Call',
  'Wait'
];

const OrganizationsTab = ({
  organizations,
  activeAccount,
  dataIsFetching
}) => {

  const priorities = [];
  for (let i = 1; i <= 14; i++) priorities.push({ text: i, value: i });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, org) => (
        <OrgLink id={org.id} account={activeAccount}><strong>{org.name}</strong></OrgLink>
      ),
      sorter: (a, b) => b.name.localeCompare(a.name),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      width: '25%',
      sorter: (a, b) => a.priority - b.priority,
      defaultSortOrder: 'ascend',
      filters: priorities,
      filterMultiple: true,
      onFilter: (p, org) => (1*org.priority === 1*p)
    },
    {
      title: '# of open activities',
      dataIndex: 'openActivities',
      sorter: (a, b) => a.openActivities - b.openActivities,
    },
    {
      title: '# of open deals',
      dataIndex: 'openDeals',
      sorter: (a, b) => a.openDeals - b.openDeals,
    },
    {
      title: '# of contacts',
      dataIndex: 'numOfContacts',
      sorter: (a, b) => a.numOfContacts - b.numOfContacts,
    },
    {
      title: 'Action',
      render: (org) => {
        return actions[1*org.priority - 1];
      },
      filters: uniq(actions).map(f => ({ text: f, value: f })),
      filterMultiple: true,
      onFilter: (a, org) => (actions[1*org.priority-1] === a)
    }
  ];

  return (<>
    <Table
      pagination={{ defaultPageSize: 50, pageSizeOptions: [50, 100, 250, 500, 1000] }}
      className="table"
      loading={dataIsFetching}
      rowKey={i => i.id}
      columns={columns}
      dataSource={organizations} />
    <span className="table-total">Total organizations: <strong>{organizations.length}</strong></span>
  </>);
}

const mapState = (state) => ({
  organizations: state.organizations.filtered,
  activeAccount: state.activeAccount,
  dataIsFetching: state.ui.dataIsFetching
});

const mapDispatch = (dispatch) => ({
  
});

export default connect(mapState, mapDispatch)(OrganizationsTab);