import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import FirebaseContext from './FirebaseContext';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import { fetchAccounts } from './store/slices/accounts';
import { initUserClaims } from './store/slices/isAdmin';

import Drawer from "./components/Drawer/Drawer";
import Home from "./pages/Home/Home";
import AccountSettings from "./pages/AccountSettings/AccountSettings"
import SystemSettings from "./pages/SystemSettings/SystemSettings"
import { initCurrentUser } from "./store/slices/currentUser";
import useOrganizationsData from './hooks/useOrganizationsData';
import useUpdatesChecker from './hooks/useUpdatesChecker';
import BulkActions from "./pages/Bulk/Bulk";

import './styles/App.scss';

const App = ({ 
  fetchAccounts, 
  initUserClaims,
  initCurrentUser,
  activeAccount,
  orgTimestamp
}) => {
  const [isSignedIn, setIsSignedIn] = useState(undefined);
  const firebaseApp = useContext(FirebaseContext);
  const [mounted, setMounted] = useState(false);
  const [, fetchOrgsData] = useOrganizationsData();
  const [initUpdatesChecker] = useUpdatesChecker();

  const authUiConfig = {
    signInFlow: 'popup',
    credentialHelper: 'none',
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult(authResult, redirectUrl) {
        if (authResult.additionalUserInfo.isNewUser) {
          firebaseApp.auth().signOut();
        }
      }
    },
  };

  useEffect(() => {
    let unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
      if (user) {
        initCurrentUser({
          displayName: user.displayName,
          email: user.email,
          photoUrl: user.photoURL,
          emailVerified: user.emailVerified,
          uid: user.uid,
        });
      }
    });
    return unregisterAuthObserver;
  }, [firebaseApp]);

  useEffect(() => {
    const init = async () => {
      Promise.all([
        await fetchAccounts(),
        await initUserClaims()
      ]);
      setMounted(true);
    };

    if (isSignedIn && firebase.auth().currentUser) {
      init();
    }
  }, [isSignedIn]);

  useEffect(() => {
    const init = async () => {
      await fetchOrgsData();
    };
    activeAccount && mounted && init();
  }, [mounted, activeAccount.apiPath]);

  useEffect(() => {
    if (!orgTimestamp || !activeAccount) return;

    initUpdatesChecker(orgTimestamp, activeAccount.apiPath, async () => {
      await fetchOrgsData(true);
    });
  }, [orgTimestamp, activeAccount.apiPath]);

  return (
    <Router>
      <div className={"app " + (isSignedIn ? "app_drawer-open" : "")}>
        {!isSignedIn && 
          <StyledFirebaseAuth className="firebase-ui-auth" uiConfig={authUiConfig}
            firebaseAuth={firebaseApp.auth()} /> }

        {(isSignedIn && firebase.auth().currentUser && mounted) ? <React.Fragment>
          <Drawer />

          <div className="container">
            <Switch>
              <Route path="/planning"><BulkActions /></Route>
              <Route path="/account-settings"><AccountSettings /></Route>
              <Route path="/system-settings"><SystemSettings /></Route>
              <Route path="/"><Home /></Route>
            </Switch>
          </div> 

        </React.Fragment> : null}
      </div>
    </Router>
  );
}

const mapState = (state) => ({
  activeAccount: state.activeAccount,
  accounts: state.accounts,
  orgTimestamp: state.organizations.timestamp
});

const mapDispatch = (dispatch) => ({
  fetchAccounts: () => dispatch(fetchAccounts()),
  initUserClaims: () => dispatch(initUserClaims()),
  initCurrentUser: (user) => dispatch(initCurrentUser(user))
});

export default connect(mapState, mapDispatch)(App);