import { createSlice } from '@reduxjs/toolkit';

const ui = createSlice({
  name: 'ui',
  initialState: {},
  reducers: {
    setUiFlag: (state, action) => {
      state[action.payload.key] = action.payload.value;
      return state;
    },
  },
});

const { setUiFlag } = ui.actions;
export { setUiFlag };

export default ui.reducer;