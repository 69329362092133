import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';


const isAdmin = createSlice({
  name: 'isAdmin',
  initialState: false,
  reducers: {
    setIsAdmin: (state, action) => action.payload,
  },
});

const initUserClaims = () => async (dispatch, getState) => {
  try {
    const res = await firebase.auth().currentUser.getIdTokenResult();
    const value = !!(res.claims && res.claims.role && res.claims.role === 'admin');
    dispatch(isAdmin.actions.setIsAdmin(value));
    
    return value;
  } catch(err) {
    console.log(err);
    return err;
  }
}

export { initUserClaims };
export default isAdmin.reducer;