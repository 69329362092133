import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Tooltip } from 'antd';

import styles from './Drawer.module.scss';

const NavigationMenu = ({
  isAdmin,
  accounts,
  activeAccount,
}) => {
  const location = useLocation();
  const account = accounts.find(acc => acc.apiPath === activeAccount.apiPath);
  const isAccountAdmin = !!(account && account.users);

  return <>
    <Menu className={styles['drawer-menu']}
      selectedKeys={location.pathname === '/' ? ['root'] : [location.pathname.replace('/', '')]}
      mode="vertical">
      <Menu.Item key="root">
        <Link to="/"><span>CRM Overview</span></Link>
      </Menu.Item>
      {(isAdmin || (isAccountAdmin && account.apiPath === 'nordicengineeringaps-e75ea1'))&& 
        <Menu.Item key="planning">
          <Link to="/planning"><span>Planning</span></Link>
        </Menu.Item>
      }
      <Menu.Item key="2" disabled>
        <Tooltip title="Coming soon" placement="bottom">
          <span>Campaigns</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="3" disabled>
        <Tooltip title="Coming soon" placement="bottom">
          <span>Datadriver</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="4" disabled>
        <Tooltip title="Coming soon" placement="bottom">
          <span>Reports</span>
        </Tooltip>
      </Menu.Item>
    </Menu>

    {(isAccountAdmin || isAdmin) ? (
      <Menu className={`${styles['drawer-menu']} ${styles['drawer-menu_bottom']}`}
        mode="vertical"
        selectedKeys={[
          ({ '/account-settings': 'accountSettings', '/system-settings': 'systemSettings' }[location.pathname])
        ]}>
        {(isAccountAdmin || isAdmin) ? <Menu.Item key="accountSettings">
          <Link to="/account-settings"><span>Account settings</span></Link>
        </Menu.Item> : null}

        {isAdmin ? <Menu.Item key="systemSettings">
          <Link to="/system-settings"><span>System settings</span></Link>
        </Menu.Item> : null}
      </Menu>
    ) : null}
  </>;
}

const mapState = (state) => ({
  accounts: state.accounts,
  activeAccount: state.activeAccount,
  isAdmin: state.isAdmin,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(NavigationMenu);