import { createSlice } from '@reduxjs/toolkit';
import { 
  getAllUsers, 
  changeSystemUserRole, 
  inviteUser as inviteUserFn,
  deleteUser as deleteUserFn
} from '../../functions';

const systemUsers = createSlice({
  name: 'systemUsers',
  initialState: [],
  reducers: {
    initUsers: (state, action) => {
      if (action.payload) return action.payload;
      return [];
    },
    changeRole: (state, action) => {
      let index = state.findIndex(u => u.uid === action.payload.uid);
      state[index].customClaims = { role: action.payload.role };
      return state;
    },
    addNewUser: (state, action) => {
      state.push(action.payload);
      return state;
    },
    removeUser: (state, action) => {
      const index = state.findIndex(a => a.uid === action.payload);
      state.splice(index, 1);
      return state;
    }
  }
});

const fetchUsers = () => async (dispatch, getState) => {
  let state = getState();

  try {
    const res = await getAllUsers();
    const resArray = Object.keys(res).map(u => res[u]);
    dispatch(systemUsers.actions.initUsers(resArray));
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const changeUserRole = (uid, role) => async (dispatch, getState) => {
  try {
    const res = await changeSystemUserRole(uid, role);
    dispatch(systemUsers.actions.changeRole({ uid, role }));
    return res;
  } catch(err) {
    console.log(err);
    return err;
  }
};

const inviteUser = (user) => async (dispatch, getState) => {
  try {
    const userDetails = await inviteUserFn(user);
    dispatch(systemUsers.actions.addNewUser(userDetails));
    return userDetails;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

const deleteUser = (uid) => async (dispatch, getState) => {
  try {
    await deleteUserFn(uid);
    dispatch(systemUsers.actions.removeUser(uid));
    return uid;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

const { initUsers } = systemUsers.actions;
export { initUsers, fetchUsers, changeUserRole, inviteUser, deleteUser };

export default systemUsers.reducer;